import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from "react-bootstrap/cjs/Row";
import DataTable from 'react-data-table-component';
import {Col} from 'react-bootstrap';
// import ExportToCSV from "./exportToCSV.tsx";
import ExportToXLSX from "./exportToXLSX.tsx";
import './index.css';
import LoadingSpinner from "./loadingSpinner";
import {getColumns} from "./columns";

const Products = ( {token} ) => {
    const [loaded, setLoaded] = useState( false );
    const [products, setProducts] = useState( [] );

    const columns = getColumns(
        new Map( [[],] ),
        ['Article', 'SKU', 'Description', 'Price', 'Price currency', 'Price AED', 'Cost', 'Cost currency', 'Cost AED', 'Status', 'Date Created', 'Date Changed']
    );

    useEffect( () => {
        fetch( '/api/panel/products',
            {
                method: 'GET',
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'x-auth': token
                }
            } )
            .then( ( data ) => data.json() )
            .then( ( data ) => {
                console.log( data )
                setProducts( data );
            } )
            .then( () => {
                setLoaded(true);
            } )

    }, [token] )


    return (
        (!loaded ?
            LoadingSpinner( false )
            :
            <DataTable
                /*title="Product List"*/
                keyField={"code"}
                striped={true}
                columns={columns}
                data={products}
                pagination
                defaultSortFieldId={"Date Created"}
                defaultSortAsc={false}
                persistTableHead
                actions={[
                    <Row>
                        {/*
                    <Col className="col-auto">
                        <ExportToCSV data={products} fn="Products"/>
                    </Col>
*/}
                        <Col className="col-auto">
                            <ExportToXLSX data={products}
                                          fn="Products"
                                          sn="Products"
                                          header={columns.map( value => value.sortField )}
                                          headerNames={columns.map( value => value.name )}
                            />
                        </Col>
                    </Row>
                ]}
            />)
    )
}

export default Products;
