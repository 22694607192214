import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from "react-bootstrap/cjs/Row";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink } from 'react-router-dom'

const Header = ({logged, handleLogout}) => {

    if (logged === true) {
        return (
            <Navbar bg="dark" expand={true} className="" fixed="top" variant="dark">
                <Navbar.Brand><img src="/cardiehLogoTransparentInner.png" style={{height: 45, marginLeft: 8}} alt="" className="img-fluid rounded" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <NavLink to="/sales" className="headerLink" >Sales</NavLink>
                        <NavLink to="/products" className="headerLink">Products</NavLink>
                        <NavLink to="/orders" className="headerLink">Orders</NavLink>
                        {/*<NavLink to="/order" className="headerLink">New order</NavLink>*/}
                        {/*<Nav.Link><NavLink to="/resellers" className="headerLink">Resellers</NavLink></Nav.Link>*/}
                    </Nav>
                    <Nav>
                        <NavLink to="/profile" className="headerLink">Profile</NavLink>
                        <NavLink to="/" className="headerLink" onClick={handleLogout}>Logout</NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
    else return (
        <Row className="headerText mb-3 mb-xl-5 justify-content-end">
            <div className="col-5 col-sm-4 col-md-3 col-lg-2 p-1">
                <img src="/cardiehLogoTransparent.png" alt="" className="img-fluid rounded"/>
            </div>
        </Row>
    );
}
export default Header;