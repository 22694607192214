import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from "react-bootstrap/cjs/Row";
import DataTable from 'react-data-table-component';
import {Col, Dropdown, DropdownButton} from 'react-bootstrap';
// import ExportToCSV from "./exportToCSV.tsx";
import ExportToXLSX from "./exportToXLSX.tsx";
import './index.css';
import LoadingSpinner from "./loadingSpinner";
import {getColumns} from "./columns";

const Sales = ( {token} ) => {

    const [loaded, setLoaded] = useState( false );
    const [salesData, setSalesData] = useState( [] );
    const [filterStatus, setFilterStatus] = useState( "" );
    const [filterOrderId, setFilterOrderId] = useState( "" );
    const [filterDateFrom, setFilterDateFrom] = useState( "" );
    const [filterDateTill, setFilterDateTill] = useState( "" );
    const [filterActivationDateFrom, setFilterActivationDateFrom] = useState( "" );
    const [filterActivationDateTill, setFilterActivationDateTill] = useState( "" );

    const columns = getColumns(
        new Map( [[],] ),
        ['Order', 'Article', 'SKU', 'Description', 'Price', 'Price currency', 'Price AED', 'Cost', 'Cost currency', 'Cost AED', 'Status', 'Code', 'Code issued (GMT)', 'Real Key',
            'Activation Date (GMT)', 'Reseller']
    );

    useEffect( () => {
        let bufferFilterStatus;
        if ( filterStatus === "ALL" ) {
            bufferFilterStatus = "";
        }
        else {
            bufferFilterStatus = filterStatus;
        }
        fetch( '/api/panel/sales?' + new URLSearchParams( {
            status: bufferFilterStatus,
            orderId: filterOrderId,
            dateFrom: filterDateFrom,
            dateTill: filterDateTill,
            activatedFrom: filterActivationDateFrom,
            activatedTill: filterActivationDateTill
        } ),
            {
                method: 'GET',
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'x-auth': token
                }
            } )
            .then( ( data ) => data.json() )
            .then( ( data ) => {
                console.log( "Here is response" )
                console.log( data )
                setSalesData( data );
            } )
            .then( () => {
                    setLoaded( true );
                }
            )
            .catch( reason => {
                setLoaded( true );
                console.log( "Error ", reason )
                console.error( reason )
            } );

    }, [token, filterStatus, filterOrderId, filterDateFrom, filterDateTill, filterActivationDateFrom, filterActivationDateTill] )


    const handleSelect = ( e ) => {
        console.log( "EVENT", e );
        setFilterStatus( e );
    }

    const handleFilterDateFrom = ( value ) => {
        if ( ((new Date( value )) < (new Date( filterDateTill ))) || (filterDateTill === "") || (value === "")) {
            setFilterDateFrom( value );
            console.log( "From ", filterDateFrom )
        }
    }

    const handleFilterActivationDateFrom = ( value ) => {
        if ( ((new Date( value )) < (new Date( filterActivationDateTill ))) || (filterActivationDateTill === "") || (value === "")) {
            setFilterActivationDateFrom( value );
            console.log( "Activation from ", filterActivationDateFrom )
        }
    }

    const handleFilterDateTill = ( value ) => {
        if ( ((new Date( value )) > (new Date( filterDateFrom ))) || (filterDateFrom === "") || (value === "")) {
            setFilterDateTill( value );
            console.log( "Till ", filterDateTill )
        }
    }

    const handleFilterActivationDateTill = ( value ) => {
        if ( ((new Date( value )) > (new Date( filterActivationDateFrom ))) || (filterActivationDateFrom === "") || (value === "")) {
            setFilterActivationDateTill( value );
            console.log( "Activation till ", filterActivationDateTill )
        }
    }

    return (
        (!loaded ?
            LoadingSpinner( false )
            :
            <DataTable
                /*title="Product List"*/
                keyField={"trnx"}
                striped={true}
                columns={columns}
                data={salesData}
                pagination
                /*paginationResetDefaultPage={resetPaginationToggle}*/
                defaultSortFieldId={"Code issued (GMT)"}
                defaultSortAsc={false}
                subHeaderAlign="left"
                subHeaderWrap={false}
                subHeader
                subHeaderComponent={[
                    <Row className="justify-content-start mx-0 row-cols-1 row-cols-md-2 row-cols-lg-auto">
                        <Col>
                            <input placeholder="Order ID"
                                   className="orderId form-control"
                                   value={filterOrderId}
                                   onChange={( e ) => setFilterOrderId( e.target.value )}>
                            </input>
                        </Col>
                        {/*<Col>
                        <Dropdown onSelect={( e ) => {
                            handleSelect( e )
                        }}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Choose option
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="RESERVE" key="RESERVE">RESERVE</Dropdown.Item>
                                <Dropdown.Item eventKey="SOLD" key="SOLD">SOLD</Dropdown.Item>
                                <Dropdown.Item eventKey="REVOKED" key="REVOKED">REVOKED</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>*/}
                        <Col>
                            <DropdownButton variant="secondary" id="dropdown-basic-button" title={filterStatus || 'Select status'} onSelect={( e ) => {
                                handleSelect( e )
                            }}>
                                <Dropdown.Item eventKey="ALL" key="ALL">ALL</Dropdown.Item>
                                <Dropdown.Item eventKey="RESERVE" key="RESERVE">RESERVE</Dropdown.Item>
                                <Dropdown.Item eventKey="SOLD" key="SOLD">SOLD</Dropdown.Item>
                                <Dropdown.Item eventKey="REVOKED" key="REVOKED">REVOKED</Dropdown.Item>
                            </DropdownButton>
                        </Col>
                        <Col className="dateDiv">
                            <label htmlFor="dateBefore">Date from</label>
                            <input min="2020-01-01"
                                   max="2040-12-31"
                                   type="date"
                                /*type="text"
                                onFocus={(e) => (e.target.type = "date")}*/
                                /* type="text"
                                 onFocus={(e) => (e.target.type = "date")}
                                 onBlur={(e) => (e.target.type = "text")}*/
                                   className="dateFilter form-control"
                                   value={filterDateFrom}
                                   name="dateBefore"
                                   placeholder="Enter date from"
                                   onChange={( e ) => handleFilterDateFrom( e.target.value )}
                            />
                        </Col>
                        <Col className="dateDiv">
                            <label htmlFor="dateAfter">till</label>
                            <input min="2020-01-01"
                                   max="2040-12-31"
                                   type="date"
                                /*type="text"
                                onFocus={(e) => (e.target.type = "date")}*/
                                /* type="text"
                                 onFocus={(e) => (e.target.type = "date")}
                                 onBlur={(e) => (e.target.type = "text")}*/
                                   className="dateFilter form-control"
                                   value={filterDateTill}
                                   name="dateAfter"
                                   placeholder="Enter date till"
                                   onChange={( e ) => handleFilterDateTill( e.target.value )}
                            />
                        </Col>
                        <Col className="dateDiv">
                            <label htmlFor="filterActivationDateFrom">Activation date from</label>
                            <input min="2020-01-01"
                                   max="2040-12-31"
                                   type="date"
                                   className="dateFilter form-control"
                                   value={filterActivationDateFrom}
                                   id="filterActivationDateFrom"
                                   name="filterActivationDateFrom"
                                   placeholder="Enter date from"
                                   onChange={( e ) => handleFilterActivationDateFrom( e.target.value )}
                            />
                        </Col>
                        <Col className="dateDiv">
                            <label htmlFor="filterActivationDateTill">till</label>
                            <input min="2020-01-01"
                                   max="2040-12-31"
                                   type="date"
                                   className="dateFilter form-control"
                                   value={filterActivationDateTill}
                                   id="filterActivationDateTill"
                                   name="filterActivationDateTill"
                                   placeholder="Enter date till"
                                   onChange={( e ) => handleFilterActivationDateTill( e.target.value )}
                            />
                        </Col>


                        {/* <Col className="col-2">

                    </Col>*/}
                    </Row>,
                    <Col className="d-flex justify-content-end">
                        <ExportToXLSX data={salesData}
                                      fn="Sales"
                                      sn="Sales"
                                      header={columns.map( value => value.sortField )}
                                      headerNames={columns.map( value => value.name )}
                        />
                    </Col>

                    /*<Row className="mx-0 filterContainer">
                        {/!*{new Date(filterDateFromText).toLocaleDateString()}*!/}
                        {subHeaderFilterDateFromComponent}
                        {subHeaderFilterDateTillComponent}
                        {subHeaderFilterActivationDateFromComponent}
                        {subHeaderFilterActivationDateTillComponent}
                        {subHeaderFilterKeyComponent}
                        {subHeaderFilterCodeComponent}
                        {subHeaderFilterResellerComponent}
                        <Button className="filterClearButton"
                                type="button"
                                onClick={clearFilters}>
                            Clear
                        </Button>
                        <Button className="filterClearButton"
                                type="button"
                                onClick={searchFromAPI}>
                            Search
                        </Button>
                        {/!*{subHeaderFilterClearButton}
                        {subHeaderSearchButton}*!/}
                    </Row>*/
                ]}
                /*selectableRows*/
                persistTableHead
                customStyles={{
                    subHeader: {
                        style: {
                            paddingLeft: '0px',
                            paddingRight: '0px'
                        },
                    }
                }}
                /*actions={[
                    <Row>
                        {/!*
                        <Col className="col-auto">
                            <ExportToCSV data={salesData}/>
                        </Col>*!/}


                    </Row>
                ]}*/
            />)
    )
}

export default Sales;
