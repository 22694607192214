import React from "react";
import './index.css';

const LoadingSpinner = ( background ) => {
    if ( background ) {
        return (
            <div className="d-flex justify-content-center align-items-center" id="loadingSpinnerDiv">
                <div className="spinner-border" role="status" id="loadingSpinner"/>
            </div>
        );
    }
    else {
        return (
            <div className="d-flex justify-content-center align-items-center" id="loadingSpinnerDivNoBackground">
                <div className="spinner-border" role="status" id="loadingSpinner"/>
            </div>
        );
    }
}

export default LoadingSpinner;