import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DataTable from 'react-data-table-component';
import {Button, Col, Row} from 'react-bootstrap';
//import ExportToCSV from "./exportToCSV.tsx";
//import ExportToXLSX from "./exportToXLSX.tsx";
import './index.css';
import {useNavigate} from "react-router-dom";
import LoadingSpinner from "./loadingSpinner";
import {getColumns} from './columns';

const Orders = ( {token} ) => {

    const navigate = useNavigate();
    const [loaded, setLoaded] = useState( false );
    // const [from, setFrom] = useState( [] );
    const [orders, setOrders] = useState( [] );
    const [filterDateFrom, setFilterDateFrom] = useState( "" );
    const [filterDateTill, setFilterDateTill] = useState( "" );
    const modalDownload = ( row, showTo, type ) => {
        console.log( "Orders: ", orders );

        if (typeof row.order_id === 'undefined') {
            console.log("Invoice");
            window.location = '/api/panel/download?order=&showTo=' + showTo + '&type=' + type + '&dateFrom=' + filterDateFrom + '&dateTill=' + filterDateTill;
        }
        else {
            console.log( "Row: ", row );
            console.log( "Row id: ", row.order_id );
            window.location = '/api/panel/download?order=' + row.order_id + '&showTo=' + showTo + '&type=' + type + '&dateFrom=' + filterDateFrom + '&dateTill=' + filterDateTill;
        }

        // return window.open('/api/panel/download?order=' + row.order_id);
    }

    /*const modalDownloadForMyReseller = ( row ) => {
        console.log( "Orders: ", orders );
        console.log( "Row: ", row );
        console.log( "Row id: ", row.order_id );
        //window.open('/api/panel/download?order=' + row.order_id);
        window.location = '/api/panel/download?order=' + row.order_id + '&showTo=myReseller';
        // return window.open('/api/panel/download?order=' + row.order_id);
    }*/

    const columns = getColumns(
        new Map( [['modalDownload', modalDownload], ['modalDownloadForMyReseller', modalDownload]] ),
        ['Order ID', 'Date', 'Reseller', 'Download Button', 'Download Button For My Reseller', 'Download Invoice For My Reseller']
    );

    const handleFilterDateFrom = ( value ) => {
        if ( ((new Date( value )) < (new Date( filterDateTill ))) || (filterDateTill === "") || (value === "")) {
            setFilterDateFrom( value );
            console.log( "From ", filterDateFrom )
        }
    }

    const handleFilterDateTill = ( value ) => {
        if ( ((new Date( value )) > (new Date( filterDateFrom ))) || (filterDateFrom === "") || (value === "")) {
            setFilterDateTill( value );
            console.log( "Till ", filterDateTill )
        }
    }
    /*const columns = [
        {
            name: 'Order ID',
            selector: row => row.order_id,
            sortable: true,
            sortField: 'order_id',
            width: '8rem',
        },
        {
            name: 'Date',
            selector: row => row.order_date,
            sortable: true,
            format: row => new Date( row.order_date ).toLocaleString(),
            sortField: 'order_date',
            width: '12rem',
        },
        {
            name: '',
            cell: row => <Button variant="link" onClick={() => {
                modalDownload( row )
            }
            }>Download</Button>
        }
    ];*/


    useEffect( () => {
        fetch( '/api/panel/orders',
            {
                method: 'GET',
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'x-auth': token
                }
            } )
            .then( ( data ) => data.json() )
            .then( ( data ) => {
                console.log( data )
                setOrders( data );
            } )
            .then( () => {
                setLoaded( true );
            } )

    }, [token] )


    return (
        (!loaded ?
            LoadingSpinner( false )
            :
            <DataTable
                /*title="Product List"*/
                keyField={"code"}
                striped={true}
                columns={columns}
                data={orders}
                pagination
                /*paginationResetDefaultPage={resetPaginationToggle}*/
                subHeader
                subHeaderAlign="left"
                /*selectableRows*/
                /*persistTableHead*/
                defaultSortFieldId={"Date"}
                defaultSortAsc={false}
                subHeaderComponent={[
                    <Row className="mx-0 row-cols-1 row-cols-md-2 row-cols-lg-auto">
                        <Col className="dateDiv">
                            <label htmlFor="dateBefore">Date from</label>
                            <input min="2020-01-01"
                                   max="2040-12-31"
                                   type="date"
                                /*type="text"
                                onFocus={(e) => (e.target.type = "date")}*/
                                /* type="text"
                                 onFocus={(e) => (e.target.type = "date")}
                                 onBlur={(e) => (e.target.type = "text")}*/
                                   className="dateFilter form-control"
                                   value={filterDateFrom}
                                   name="dateBefore"
                                   placeholder="Enter date from"
                                   onChange={( e ) => handleFilterDateFrom( e.target.value )}
                            />
                        </Col>
                        <Col className="dateDiv">
                            <label htmlFor="dateAfter">till</label>
                            <input min="2020-01-01"
                                   max="2040-12-31"
                                   type="date"
                                /*type="text"
                                onFocus={(e) => (e.target.type = "date")}*/
                                /* type="text"
                                 onFocus={(e) => (e.target.type = "date")}
                                 onBlur={(e) => (e.target.type = "text")}*/
                                   className="dateFilter form-control"
                                   value={filterDateTill}
                                   name="dateAfter"
                                   placeholder="Enter date till"
                                   onChange={( e ) => handleFilterDateTill( e.target.value )}
                            />
                        </Col>
                        <Col>
                            <Button className="invoiceButton" variant="success" onClick={
                                () => {modalDownload('', '', 'invoice')}
                            }>Download invoice</Button>
                        </Col>
                    </Row>,
                    <Col className="d-flex justify-content-end">
                        <Button className="newOrderButton" variant="success" onClick={() => {
                            navigate( '/order' )
                        }}>New order</Button>
                    </Col>
                ]}
                /*actions={[
                    <Button className="newOrderButton" variant="success" onClick={() => {
                        navigate( '/order' )
                    }}>New order</Button>
                ]}*/
            />)
    )
}


export default Orders;
