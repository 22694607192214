import * as React from 'react';
import * as XLSX from 'xlsx';
import {Button} from "react-bootstrap";

type DataObject = {
    [key: string]: unknown;
};

type ExportToXLSXProps = {
    data: DataObject[];
    sn: string;
    fn: string;
    header: string[];
    headerNames: string[]
};

const ExportToXLSX: React.FC<ExportToXLSXProps> = ({ data, sn, fn, header, headerNames }) => {

    const handleExport = () => {
        let newData: DataObject[] = []
        let headerData: DataObject = {}
        let k:number = 0
        for (const headerName of headerNames) {
            headerData[header[k]] = headerName;
            k++;
        }
        /*newData = newData.concat(headerData).concat(data);*/

        newData = newData.concat(headerData);
        for (const dataRow of data){
            let col:number = 0
            let rowDataBuffer: DataObject = {}
            headerNames.forEach(()=> {
                rowDataBuffer[header[col]] = dataRow[header[col]];
                col++;
            });
            newData = newData.concat(rowDataBuffer)
        }

        const sheet = XLSX.utils.json_to_sheet(newData, {header: header, skipHeader: true, cellDates: true});
        // const sheet = XLSX.utils.json_to_sheet(data);
        const sheetName = sn;
        const fileName = fn + "-" + new Date(Date.now()).toISOString()
            .replace(':', '.')
            .replace('+', '')
            .replace(/([:+ ])/, '_')
            .replace('Z', '');
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, sheet, sheetName);
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    };

    return (
        <Button
            className="exportButton"
            type="button"
            onClick={handleExport}>
            Export XLSX
        </Button>
    );
};

export default ExportToXLSX;
