import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from "react-bootstrap/cjs/Row";
import DataTable from 'react-data-table-component';
import {Modal, Button, CloseButton, Col, Container, Table, Form} from 'react-bootstrap';
import './index.css';
import LoadingSpinner from "./loadingSpinner";
import {getColumns} from "./columns";

const OrderProducts = ( {token} ) => {
    const getFromLocalStorage = () => {
        return JSON.parse( localStorage.getItem( "localStorageRows" ) )
    }

    const [loaded, setLoaded] = useState( false );
    const [products, setProducts] = useState( [] );
    const [filtered, setFiltered] = useState( [] );
    const [search, setSearch] = useState( "" );
    const [rowsToOrder, setRowsToOrder] = useState( getFromLocalStorage() || [] );
    const [toggledClearRows, setToggleClearRows] = React.useState( false );
    const [quantityCheck, setQuantityCheck] = useState( "Normal quantity" );
    const [showFirstModal, setShowFirstModal] = useState( false );
    const [showSecondModal, setShowSecondModal] = useState( false );
    const [buttonDisabled, setButtonDisabled] = useState( false );
    const [submitOrderButtonDisabled, setSubmitOrderButtonDisabled] = useState( true );
    const [orderStatus, setOrderStatus] = useState( "" );
    const [orderReseller, setOrderReseller] = useState( "" );
    const [newReseller, setNewReseller] = useState( "" );
    const [resellers, setResellers] = useState( [] );
    const [orderData, setOrderData] = useState( [] );
    const [focusedInputId, setFocusedInputId] = useState("");

    const columns = getColumns(
        new Map( [[],] ),
        ['Article', 'SKU', 'Description', 'Price', 'Price AED', 'Cost', 'Cost AED', 'Status', 'Date Created', 'Date Changed',]
    );

    const firstModalClose = () => setShowFirstModal( false );

    const secondModalClose = () => {
        handleClearRowsToOrder();
        setShowSecondModal( false )
    };

    const secondModalShow = () => setShowSecondModal( true );

    const handleClickOnOrderRow = ( article ) => {
        console.log("Id to highlight ", article);
        setFocusedInputId(article);
        document.getElementById("input" + article).focus();
    }

    const handleClickOnRow = ( row ) => {
        let bufferRowsToOrder = JSON.parse( JSON.stringify( rowsToOrder ) );
        if ( rowsToOrder.indexOf( row ) === -1 ) {
            row.qty = 1;
            bufferRowsToOrder.push( row );
            setFocusedInputId(row.article);
        }
        setRowsToOrder( bufferRowsToOrder );
    }

    const handleSelectedRows = ( {selectedRows} ) => {
        let bufferRowsToOrder = JSON.parse( JSON.stringify( rowsToOrder ) );
        selectedRows.forEach( ( item ) => {
            if ( rowsToOrder.indexOf( item ) === -1 ) {
                item.qty = 1;
                bufferRowsToOrder.push( item );
                setFocusedInputId(item.article);
            }
        } );
        //bufferRowsToOrder.push(selectedRows[0]);
        setRowsToOrder( bufferRowsToOrder );
        setToggleClearRows( !toggledClearRows );
    };

    const handleClearRowsToOrder = () => {
        console.log( "Rows to order handleClearRowsToOrder ", rowsToOrder );
        setRowsToOrder( [] );
    }

    useEffect( () => {
        fetch( '/api/panel/products',
            {
                method: 'GET',
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'x-auth': token
                }
            } )
            .then( ( data ) => data.json() )
            .then( ( data ) => {
                console.log( data );
                setProducts( data );
            } )
            .then( () => {
                setLoaded( true );
            } )

    }, [token] )

    useEffect( () => {
        fetch( '/api/panel/resellers',
            {
                method: 'GET',
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'x-auth': token
                }
            } )
            .then( ( data ) => data.json() )
            .then( ( data ) => {
                let bufferResellersArray = [];
                for ( const element of data ) {
                    if ( (element !== "") && (element !== null) ) {
                        console.log( element );
                        bufferResellersArray.push( element );
                    }
                }
                //bufferResellersArray.push( "Test reseller" );
                setResellers( bufferResellersArray );
            } )
    }, [token, orderReseller, newReseller] )

    useEffect( () => {
        setFiltered(
            products.filter(
                item => (!((rowsToOrder) && rowsToOrder.find( x => (x.article === item.article) ))) &&
                    ((item.article && item.article.toLowerCase().includes( search.toLowerCase() ))
                        || (item.id && item.id.toLowerCase().includes( search.toLowerCase() ))
                        || (item.description && item.description.toLowerCase().includes( search.toLowerCase() )))
            ) )
    }, [products, search, rowsToOrder] )

    useEffect( () => {
        localStorage.setItem( "localStorageRows", JSON.stringify( rowsToOrder ) );
        if (document.getElementById("input" + focusedInputId)) {
            console.log("Focused input id ", focusedInputId);
            document.getElementById("input" + focusedInputId).focus();
        }
    }, [rowsToOrder, focusedInputId] )

    function handleQtyChange( e, item ) {
        //deepCopy
        let bufferRows = JSON.parse( JSON.stringify( rowsToOrder ) );
        for ( let row of bufferRows ) {
            if ( row.article === item.article ) {
                if ( e.target.value <= 10000 ) {
                    row.qty = e.target.value;
                }
                else {
                    row.qty = 10000;
                }
            }
        }
        setRowsToOrder( bufferRows );
    }

    function handleCheckOrder() {
        let sumOfQuantities = 0;
        for ( let row of rowsToOrder ) {
            sumOfQuantities = sumOfQuantities + Number( row.qty );
        }

        if ( sumOfQuantities <= 5 * rowsToOrder.length ) {
            console.log( "Length of rowsToOrder: ", rowsToOrder.length )
            setQuantityCheck( "Small quantity" );
        }
        else {
            if ( sumOfQuantities > 1000 * rowsToOrder.length ) {
                console.log( "Length of rowsToOrder: ", rowsToOrder.length )
                setQuantityCheck( "Big quantity" );
            }
            else {
                console.log( "Length of rowsToOrder: ", rowsToOrder.length )
                setQuantityCheck( "Normal quantity" );
            }
        }
        console.log( "sumOfQuantities ", sumOfQuantities );
        setShowFirstModal( true );
    }

    const handleSelect = ( e ) => {
        console.log( "EVENT", e );
        console.log( "Event target value", e.target.value );
        //console.log( "Reseller ", resellers[e] );
        console.log( "Resellers ", resellers );
        setOrderReseller( e.target.value );
        if (e.target.value !== ""){
            setSubmitOrderButtonDisabled( false );
        }
        else setSubmitOrderButtonDisabled( true );
        console.log( "Reseller ", orderReseller );
    }

    const handleNewReseller = ( e ) => {
        console.log( "EVENT", e );
        console.log( "Event target value", e.target.value );
        //console.log( "Reseller ", resellers[e] );
        setNewReseller( e.target.value );
        setOrderReseller( e.target.value );
        if (e.target.value !== ""){
            setSubmitOrderButtonDisabled( false );
        }
        else setSubmitOrderButtonDisabled( true );
        console.log( "Reseller ", orderReseller );
    }

    function handleSubmitOrder() {
        setButtonDisabled( true );
        //const reseller = "SRP"

        const requestData = JSON.stringify( {reseller: orderReseller, lines: rowsToOrder} );
        console.log( requestData );
        fetch( '/api/panel/orders', {
            method: 'post',
            body: requestData,
            headers: {
                'Content-Type': 'application/json',
                'x-auth': token
            }
        } )
            .then( value => {
                console.log( "Value ", value );
                setOrderStatus( "Uncaught error" );
                if ( value.status === 200 ) {
                    value.json().then(
                        ( data ) => {
                            console.log( "Data from order response ", data )
                            setOrderData( data );
                            console.log( "VALUE OK" )
                            setOrderStatus( "200" );
                            console.log( "200" );
                            firstModalClose();
                            secondModalShow();
                        }
                    )
                }
                else if ( value.status === 503 || value.status === 404 ) {
                    console.log( "Value status", value.status );
                    console.log( "Value ", value );
                    console.log( "NOT OK" )
                    setOrderStatus( "503, 404" );
                    console.log( "503, 404" );
                    firstModalClose();
                    secondModalShow();
                }
                firstModalClose();
                secondModalShow();
                setButtonDisabled( false );
            } )
            .catch( reason => {
                setOrderStatus( "Catch promise rejected" );
                console.log( "Catch promise rejected" );
                firstModalClose();
                secondModalShow();
                setButtonDisabled( false );
                console.error( reason )
            } );
    }

    const modalDownload = () => {
        handleClearRowsToOrder();
        setShowSecondModal( false );
        window.location = '/api/panel/download?order=' + orderData.order_id;
    }

    return ([
        (!Boolean( rowsToOrder.length ) && <label className="fs-3 orderTable">
            Choose products to order
        </label>),
        (Boolean( rowsToOrder.length ) && <Col><label className="fs-3 orderTableLabel">
            Products to order
        </label><br/>
            <div className="orderTableRadioDiv">
                <label className="fs-5 orderTableLabel">
                    Select reseller
                </label>
                {resellers.map( ( option ) => (
                    <div>
                        {/* <input className="form-check-input orderTableRadio"
                               type="radio"
                               name={option}
                               id="flexRadioDefault1"
                               value={option}
                               checked={orderReseller === option}
                               onChange={handleSelect}
                        />
                        <label className="form-check-label" htmlFor={option}>
                            {option}
                        </label>*/}
                        <Form.Check className="orderTableRadio"
                                    type="radio"
                                    name={option}
                                    id="flexRadioDefault1"
                                    value={option}
                                    checked={orderReseller === option}
                                    onChange={handleSelect}
                                    label={option}
                        />
                    </div>
                ) )}
                <label htmlFor="addReseller" className="fs-5 newResellerLabel">
                    Or add a new reseller
                </label>
                <Col className="col-4 dateDiv">
                    <input placeholder="New reseller name" name="addReseller" className="form-control newResellerInput" value={newReseller}
                           onChange={handleNewReseller}>
                    </input>
                </Col>
            </div>
        </Col>),
        <Table striped bordered hover className="orderTable">
            {Boolean( rowsToOrder.length ) &&
            (<thead>
            <tr>
                <th width="2%">SKU</th>
                <th width="3%">Article</th>
                <th>Product description</th>
                <th width="12%">Quantity</th>
                <th width="1%"></th>
            </tr>
            </thead>)}
            <tbody>
            {rowsToOrder.map( ( item ) => (
                <tr key={item.article} onClick={() => handleClickOnOrderRow(item.article)} >
                    <td>{item.id}</td>
                    <td>{item.article}</td>
                    <td>{item.description}</td>
                    <td>
                        <input
                            id={"input" + item.article}
                            defaultValue={1}
                            type="number"
                            min="1"
                            step="1"
                            value={item.qty}
                            onChange={( e ) => handleQtyChange( e, item )}
                        />
                    </td>
                    <td>
                        <CloseButton onClick={() => setRowsToOrder( rowsToOrder.filter( row => row.article !== item.article ) )}/>
                    </td>
                </tr>
            ) )}
            </tbody>
            {Boolean( rowsToOrder.length ) &&
            (<tfoot>
            <tr>
                <td colSpan="2">

                </td>
                {/*<td colSpan="3" align="right">
                    <Row className="justify-content-end">
                        <Col className="col-auto mx-1 p-0">
                            <DropdownButton variant="secondary" id="dropdown-basic-button" title={orderReseller || 'Select reseller'} onSelect={( e ) => {
                                handleSelect( e )
                            }}>
                                {resellers.map( ( item, index ) => (
                                    <Dropdown.Item key={index} eventKey={index}>
                                        {item}
                                    </Dropdown.Item>) )}
                            </DropdownButton>
                        </Col>
                        <Col className="align-self-end col-auto mx-1 p-0">
                            <Button className="btn-danger" type="button" onClick={() => handleClearRowsToOrder()}>
                                Cancel
                            </Button>
                        </Col>
                        <Col className="align-self-end col-auto ms-1 ps-0">
                            <Button className="btn-success" type="button" onClick={() => handleCheckOrder()}>
                                Submit order
                            </Button>
                        </Col>
                    </Row>
                </td>*/}
                <td colSpan="3" align="right">
                    <Button className="btn-danger mx-1" type="button" onClick={() => handleClearRowsToOrder()}>
                        Cancel
                    </Button>
                    <Button className="btn-success" disabled={submitOrderButtonDisabled} type="button" onClick={() => handleCheckOrder()}>
                        Submit order
                    </Button>
                </td>
            </tr>
            </tfoot>)}


        </Table>,
        <div>
            <Modal backdrop="static" show={showFirstModal} onHide={firstModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm order?</Modal.Title>
                </Modal.Header>

                {(quantityCheck === "Small quantity") &&
                <Modal.Body>
                    <p>That is a very small order. Are you sure you want to proceed?</p>
                </Modal.Body>}

                {(quantityCheck === "Big quantity") &&
                <Modal.Body>
                    <p>That is a very big order. Are you sure you want to proceed?</p>
                </Modal.Body>}

                {buttonDisabled && LoadingSpinner( true )}
                <Modal.Footer>
                    <Button variant="secondary" disabled={buttonDisabled} onClick={firstModalClose}>Return to order</Button>
                    <Button variant="primary" disabled={buttonDisabled} onClick={handleSubmitOrder}>Confirm order</Button>
                </Modal.Footer>
            </Modal>
        </div>,
        <div>
            <Modal backdrop="static" show={showSecondModal} onHide={secondModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Order information</Modal.Title>
                </Modal.Header>

                {(orderStatus === "200") &&
                <Modal.Body>
                    <p>Order# {orderData.order_id} successfully created</p>
                </Modal.Body>}

                {(orderStatus === "503, 404" || orderStatus === "Uncaught error") &&
                <Modal.Body>
                    <p>Order creation failed. Try again</p>
                </Modal.Body>}

                {(orderStatus === "Catch promise rejected") &&
                <Modal.Body>
                    <p>Error. Contact technical staff</p>
                </Modal.Body>}


                <Modal.Footer>
                    <Button variant="secondary" onClick={secondModalClose}>Close</Button>
                    {(orderStatus === "200") &&
                    <Button variant="primary" onClick={modalDownload}>Download</Button>}
                </Modal.Footer>
            </Modal>
        </div>,
        (!loaded ?
            LoadingSpinner( false )
            :
            <DataTable
                keyField={"article"}
                striped={true}
                columns={columns}
                data={filtered}
                pagination
                subHeader
                subHeaderComponent={[
                    <Container className="ps-0 pe-0 ms-0 me-0">
                        <Row>
                            <Col className="col-4 dateDiv">
                                <label htmlFor="filter">Search</label>
                                <input placeholder="Article, SKU or Description" name="filter" className="form-control" value={search}
                                       onChange={( e ) => setSearch( e.target.value )}>
                                </input>
                            </Col>
                        </Row>
                    </Container>
                ]}
                defaultSortFieldId={"Date Created"}
                defaultSortAsc={false}
                //subHeaderComponentProps={{className: "ps-0 pe-0 ms-0 me-0"}}
                subHeaderAlign={"left"}
                clearSelectedRows={toggledClearRows}
                selectableRows
                persistTableHead
                onSelectedRowsChange={handleSelectedRows}
                onRowClicked={( row ) => handleClickOnRow( row )}
                customStyles={{
                    subHeader: {
                        style: {
                            paddingLeft: '16px',
                            paddingRight: '16px'
                        },
                    }
                }}
            />)
    ])
}

/*const columns = [
    {
        name: 'UPC',
        selector: row => row.upc,
        sortable: true,
        sortField: 'upc',
        width: '8rem',
    },
    {
        name: 'ID',
        selector: row => row.id,
        sortable: true,
        sortField: 'id',
        width: '5rem',
    },
    {
        name: 'Product Name',
        selector: row => row.description,
        sortable: true,
        sortField: 'description',
        grow: 1,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
        sortField: 'status',
        width: '6rem',
    },
    {
        name: 'Date Created',
        selector: row => row.tsc,
        sortable: true,
        format: row => new Date( row.tsc ).toLocaleString(),
        sortField: 'tsc',
        width: '12rem',
    },
    {
        name: 'Date Changed',
        selector: row => row.tse,
        sortable: true,
        format: row => new Date( row.tse ).toLocaleString(),
        sortField: 'tse',
        width: '12rem',
    },
    {
        name: 'Price',
        selector: row => row.price,
        sortable: true,
        sortField: 'price',
        width: '5rem',
        right: true,
        compact: true,
    },

];*/

export default OrderProducts;
