import {Button} from "react-bootstrap";
import React from "react";

export const getColumns = (mapObj, columnNames) => {
    let columns;
    columns = [
        {
            id: 'Order ID',
            name: 'Order ID',
            selector: row => row.order_id,
            sortable: true,
            sortField: 'order_id',
            width: '8rem',
        },
        {
            id: 'Date',
            name: 'Date',
            selector: row => row.order_date,
            sortable: true,
            format: row => new Date( row.order_date ).toLocaleString(),
            sortField: 'order_date',
            width: '12rem',
        },
        {
            id: 'Download Button',
            name: '',
            maxWidth: '10rem',
            wrap: false,
            cell: row => <Button variant="link" onClick={() => {
                mapObj.get('modalDownload')( row )
            }
            }>Download</Button>
        },
        {
            id: 'Download Button For My Reseller',
            name: '',
            maxWidth: '15rem',
            wrap: false,
            cell: row => <Button variant="link" onClick={() => {
                mapObj.get('modalDownload')( row, 'myReseller')
            }
            }>Download for my reseller</Button>
        },
        {
            id: 'Download Invoice For My Reseller',
            name: '',
            maxWidth: '20rem',
            wrap: false,
            cell: row => <Button variant="link" onClick={() => {
                mapObj.get('modalDownload')( row, '', 'invoice')
            }
            }>Download invoice for my reseller</Button>
        },
        {
            id: 'Article',
            name: 'Article',
            selector: row => row.article,
            sortable: true,
            sortField: 'article',
            width: '18rem',
        },
        {
            id: 'SKU',
            name: 'SKU',
            selector: row => row.id,
            sortable: true,
            sortField: 'id',
            width: '5rem',
        },
        {
            id: 'Description',
            name: 'Description',
            selector: row => row.description,
            sortable: true,
            sortField: 'description',
            grow: 1,
            //maxWidth: '18rem',
            //width: '25rem',
            //minWidth: '15rem',
            //maxWidth: '25rem',
        },
        {
            id: 'Status',
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            sortField: 'status',
            width: '6rem',
        },
        {
            id: 'TRNX',
            name: 'TRNX',
            selector: row => row.trnx,
            sortable: true,
            omit: true,
            sortField: 'trnx',
            width: '6rem',
        },
        {
            id: 'Date Created',
            name: 'Date Created',
            selector: row => row.tsc,
            sortable: true,
            format: row => new Date( row.tsc ).toLocaleString(),
            sortField: 'tsc',
            width: '12rem',
        },
        {
            id: 'Date Changed',
            name: 'Date Changed',
            selector: row => row.tse,
            sortable: true,
            format: row => new Date( row.tse ).toLocaleString(),
            sortField: 'tse',
            width: '12rem',
        },
        {
            id: 'Price',
            name: 'Price',
            /*selector: row =>
                row.price !== null && row.price !== undefined?
                    String(Number(row.price.toFixed(2))) + ' '
                    + ((row.price_currency !== null && row.price_currency !== undefined)? row.price_currency : 'CURR')
                    : 'N/A',*/
            selector: row =>
                row.price_rounded !== null && row.price_rounded !== undefined && row.price_rounded !== "0"?
                    row.price_rounded + ' '
                    + ((row.price_currency !== null && row.price_currency !== undefined)? row.price_currency : 'CURR')
                    : 'N/A',
            sortable: true,
            sortField: 'price_rounded',
            width: '6rem',
            right: true,
            compact: true,
        },
        {
            id: 'Price currency',
            name: 'Price currency',
            selector: row => row.price_currency,
            sortable: true,
            sortField: 'price_currency',
            omit: true,
            width: '6rem',
            right: true,
            compact: true,
        },
        {
            id: 'Price AED',
            name: 'Price AED',
            /*selector: row => Number(row.price_aed.toFixed(2)),*/
            /*selector: row => Number(row.price_aed !== undefined ? row.price_aed.toFixed(2) : '0'),*/
            /*selector: row => row.price_aed !== null && row.price_aed !== undefined? String(Number(row.price_aed.toFixed(2))) + ' AED': 'N/A',*/
            selector: row => row.price_aed_rounded !== null && row.price_aed_rounded !== undefined && row.price_aed_rounded !== "0"? row.price_aed_rounded + ' AED': 'N/A',
            sortable: true,
            sortField: 'price_aed_rounded',
            width: '6rem',
            right: true,
            compact: true,
        },
        /*{
            id: 'Cost',
            name: 'Cost',
            selector: row => row.your_price,
            sortable: true,
            sortField: 'your_price',
            width: '5rem',
            right: true,
            compact: true,
        },*/
        {
            id: 'Cost',
            name: 'Cost',
            /*selector: row =>
                row.reseller_price !== null && row.reseller_price !== undefined ?
                    String(Number(row.reseller_price.toFixed(2))) + ' ' + ((row.reseller_currency !== null && row.reseller_currency !== undefined)? row.reseller_currency : 'CURR')
                    : 'N/A',*/
            selector: row =>
                row.reseller_price_rounded !== null && row.reseller_price_rounded !== undefined && row.reseller_price_rounded !== "0"?
                    row.reseller_price_rounded + ' ' + ((row.reseller_currency !== null && row.reseller_currency !== undefined)? row.reseller_currency : 'CURR')
                    : 'N/A',
            sortable: true,
            sortField: 'reseller_price_rounded',
            width: '6rem',
            right: true,
            compact: true,
        },
        {
            id: 'Cost currency',
            name: 'Cost currency',
            selector: row => row.reseller_currency,
            sortable: true,
            sortField: 'reseller_currency',
            omit: true,
            width: '6rem',
            right: true,
            compact: true,
        },
        /*{
            id: 'Cost AED',
            name: 'Cost AED',
            selector: row => row.your_price_aed,
            sortable: true,
            sortField: 'your_price_aed',
            width: '5rem',
            right: true,
            compact: true,
        },*/
        {
            id: 'Cost AED',
            name: 'Cost AED',
            /*selector: row => row.reseller_price_aed !== null && row.reseller_price_aed !== undefined? String(Number(row.reseller_price_aed.toFixed(2))) + ' AED' : 'N/A',*/
            selector: row => row.reseller_price_aed_rounded !== null && row.reseller_price_aed_rounded !== undefined  && row.reseller_price_aed_rounded !== "0"? row.reseller_price_aed_rounded + ' AED' : 'N/A',
            sortable: true,
            sortField: 'reseller_price_aed_rounded',
            width: '6rem',
            right: true,
            compact: true,
        },
        {
            id: 'Order',
            name: 'Order',
            selector: row => row.order,
            sortable: true,
            sortField: 'order',
            width: '8rem',
        },
        {
            id: 'Code',
            name: 'Code',
            selector: row => row.code,
            sortable: true,
            sortField: 'code',
            width: '12rem',
        },
        {
            id: 'Code issued (GMT)',
            name: 'Code issued (GMT)',
            selector: row => new Date( row.tsc ).toLocaleString(),
            sortable: true,
            sortField: 'tsc',
            width: '12rem',
        },
        {
            id: 'Real Key',
            name: 'Real Key',
            selector: row => row.realCode,
            sortable: true,
            sortField: 'realCode',
            width: '8rem',
        },
        {
            id: 'Activation Date (GMT)',
            name: 'Activation Date (GMT)',
            selector: row => row.tsused,
            sortable: true,
            format: row => row.tsused ? new Date( row.tsused ).toLocaleString() : 'N/A',
            sortField: 'tsused',
            width: '12rem',
        },
        {
            id: 'Reseller',
            name: 'Reseller',
            selector: row => row.reseller,
            sortable: true,
            sortField: 'reseller',
            width: '8rem',
        },
    ];

    let returnColumns = [];
    columnNames.forEach(columnName => {
        if (columns.find(column => columnName === column.id)){
            returnColumns.push(columns.find(column => columnName === column.id));
        }
    });
    /*return columns.filter( column => columnNames.find(columnName => columnName === column.id));*/
    return returnColumns;
}


