import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from "react-bootstrap/cjs/Row";

const Footer = ({logged}) => {
        return (
            <Row className="mb-3 mb-xl-5">
                <div className="footerText mb-0">
                    © cardieh.com 2023
                </div>
            </Row>
        );
}
export default Footer;