import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, Col, Container, Form, FormLabel} from 'react-bootstrap';
import './index.css';
import PasswordChecklist from "react-password-checklist"

const Profile = ( {token} ) => {

    const [password, setPassword] = useState( "" );
    const [passwordAgain, setPasswordAgain] = useState( "" );
    const [oldPassword, setOldPassword] = useState( "" );
    const [validPasswords, setValidPasswords] = useState(false);
    const [message, setMessage] = useState("");

    const handle = () => {
        if (validPasswords) {
         console.log( 'handle' )
         const params = new FormData();
         params.append( 'oldPassword', oldPassword )
         params.append( 'password', password )
         fetch( '/api/panel/password', {
             method: 'post',
             body: params,
             headers: {
                 'x-auth': token
             }
         } )
             .then( value => {
                 console.log( value )
                 if ( value.ok ) {
                     setMessage("Password changed")
                 }
                 else {
                    setMessage("Try again")
                 }
                 console.log( value )
             } )
             .catch( reason => {
                 console.error( reason )
             } );
    } }

    return (
        <Container>
            <Form onSubmit={handle}>
                <Col className="mb-3 col-lg-5 col-sm-9 col-12">
                    <div id="activationInfo">
                        <p className="bigLabel fs-1 mb-3 mt-3 mb-lg-0 mt-lg-0 fw-bold">
                            Change password
                        </p>
                    </div>
                    <Form.Group className="mb-3 position-relative" controlId="formBasicPassword">
                        <Form.Label className="smallLabel">Your old password:</Form.Label>
                        <Form.Control
                            className="smallPlaceholder"
                            value={oldPassword}
                            onChange={( e ) => setOldPassword( e.target.value )}
                            type="password"
                            name="passwordOld"
                            required
                            placeholder="Your old password *"
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {/*{message}*/}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 position-relative" controlId="formBasicPassword">
                        <Form.Label className="smallLabel">New password:</Form.Label>
                        <Form.Control
                            className="smallPlaceholder"
                            value={password}
                            onChange={( e ) => setPassword( e.target.value )}
                            type="password"
                            name="passwordNew"
                            required
                            placeholder="Your new password *"
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {/*{message}*/}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 position-relative" controlId="formBasicPassword">
                        <Form.Label className="smallLabel">Confirm new password:</Form.Label>
                        <Form.Control
                            className="smallPlaceholder"
                            value={passwordAgain}
                            onChange={( e ) => setPasswordAgain( e.target.value )}
                            type="password"
                            name="passwordAgain"
                            required
                            placeholder="Repeat your new password *"
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {/*{message}*/}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Button onClick={handle} type="button" className="changePasswordButton">Change password</Button>
                    </Form.Group>
                    <PasswordChecklist
                        rules={["minLength", "number", "capital", "match"]}
                        minLength={5}
                        value={password}
                        valueAgain={passwordAgain}
                        onChange={( isValid ) => { setValidPasswords(isValid) }}
                    />
                    <FormLabel className="fs-1">{message}</FormLabel>
                </Col>
            </Form>
        </Container>
    )

}

export default Profile;
