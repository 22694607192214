import {useCookies} from 'react-cookie';
import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import Header from "./Header";
import Footer from "./Footer";
import {Button, Col, Form, Row, Container} from "react-bootstrap";
import './index.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Sales from "./Sales";
import Products from "./Products";
import Profile from "./Profile";
import OrderProducts from "./OrderProducts";
// import Resellers from "./Resellers";
import Orders from "./Orders";
import LoadingSpinner from "./loadingSpinner";

const App = () => {

    const [token, setToken] = useState( [] );
    const [username, setUsername] = useState( '' );
    const [password, setPassword] = useState( '' );
    const [cookie, setCookie] = useCookies( ['panel-token'] );

    const [logged, setLogged] = useState( false );
    const [message, setMessage] = useState( '' );
    const [remember, setRemember] = useState( false );
    const [pinged, setPinged] = useState( false );

    const handleLogout = () => {
        setToken( null );
        setLogged( false );
        setCookie( 'panel-token', null )
    }
    const handle = () => {
        console.log( 'handle' )
        const params = new FormData();
        params.append( 'username', username )
        params.append( 'password', password )
        fetch( '/api/panel/login', {
            method: 'post',
            body: params
        } )
            .then( value => {
                console.log( value )
                if ( value.ok ) {
                    console.log( value )
                    value.json().then(
                        ( data ) => {
                            console.log( data )
                            console.log( data.token )
                            setToken( data.token )
                            setLogged( data.ok )
                            setMessage( data.message )
                            setCookie( 'panel-token', data.token, {
                                path: '/',
                                sameSite: true,
                                expires: remember ? new Date( Date.now() + 30 * 24 * 3600 * 1000 ) : null
                            } );
                        }
                    )
                }
                else {
                    setToken( null )
                    setLogged( false )
                    setMessage( 'Please check correctness of login and password' )
                    setCookie( 'panel-token', null, {
                        path: '/',
                        sameSite: true
                    } );
                }
                console.log( value )
            } )
            .catch( reason => {
                console.error( reason )
            } );
    }

    useEffect( () => {
        const ct = cookie['panel-token']
        console.log( ct )
        if ( ct ) {
            setToken( ct )
            fetch( '/api/panel/ping',
                {
                    method: 'GET',
                    cache: "no-cache",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        'x-auth': ct
                    }
                } )
                .then( ( data ) => data.json() )
                .then( ( data ) => {
                    console.log( data )
                    setToken( ct )
                    setLogged( data.ok )
                } )
                .then( () => {
                    setPinged( true );
                } )
        }
        else {
            setPinged( true );
        }
    }, [cookie] )

    const loginForm =
        <Container>
            <Header/>
            <Form onSubmit={handle} className="mt-xl-5">
                <Row>
                    <Col className="mb-3 col-lg-5 col-sm-9 col-12">
                        <div id="activationInfo">
                            <p className="bigLabel fs-1 mb-3 mt-3 mb-lg-0 mt-lg-0 fw-bold">
                                LOGIN
                            </p>
                        </div>
                        <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">
                            <Form.Label className="smallLabel">Your username</Form.Label>
                            <Form.Control
                                className="smallPlaceholder"
                                value={username}
                                onChange={( e ) => setUsername( e.target.value )}
                                type="username"
                                name="username"
                                required
                                placeholder="Your username *"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 position-relative" controlId="formBasicPassword">
                            <Form.Label className="smallLabel">Password</Form.Label>
                            <Form.Control
                                className="smallPlaceholder"
                                value={password}
                                onChange={( e ) => setPassword( e.target.value )}
                                type="password"
                                name="password"
                                required
                                placeholder="Your password *"
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                                {message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Row className="align-items-center">
                            <Col className="mb-3 col-lg-5 col-sm-5 col-6">
                                <Form.Group>
                                    <Button onClick={handle} type="button" className="loginButton">Login</Button>
                                </Form.Group>
                            </Col>
                            <Col className="mb-3 col-lg-5 offset-lg-2 col-sm-5 offset-sm-2 col-5 offset-1 justify-content-end">
                                <Form.Group>
                                    <Form.Check
                                        id="remember"
                                        type="checkbox"
                                        label="Remember me"
                                        checked={remember}
                                        onChange={( e ) => setRemember( e.target.checked )}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            <Footer/>
            {/*{loadingSpinner}*/}
        </Container>

    const appInternal =
        <BrowserRouter>
            <Container fluid={true}>
                <Header logged={logged} handleLogout={handleLogout}/>
                <div style={{paddingTop: 86}}>
                    <Routes>
                        <Route path="/" element={<Sales token={token}/>}/>
                        <Route path="/sales" element={<Sales token={token}/>}/>
                        <Route path="/products" element={<Products token={token}/>}/>
                        <Route path="/profile" element={<Profile token={token}/>}/>
                        <Route path="/order" element={<OrderProducts token={token}/>}/>
                        {/*<Route path="/resellers" element={<Resellers token={token}/>}/>*/}
                        <Route path="/orders" element={<Orders token={token}/>}/>
                    </Routes>
                </div>
                <Footer logged={logged}/>
            </Container>
        </BrowserRouter>

    /*const pingedElement =
        <div className="d-flex justify-content-center align-items-center" id="loadingSpinnerDiv">
            <div className="spinner-border" role="status" id="loadingSpinner"/>
        </div>*/

    return (!pinged ? LoadingSpinner(true) : (!logged ? loginForm : appInternal));
}

export default App;
